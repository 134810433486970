import React from "react";

import { modalCode, trackPageViewMixpanel } from "@earlypay/shared";
import { applicationState } from "@recoil/applications/atoms";
import { useRecoilValue } from "recoil";

import {
  ActionButton,
  Box,
  LottieAnimation,
  PageContainer,
  Text,
  useConfirmModal,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

export const Restart = () => {
  const application = useRecoilValue(applicationState);
  const name = application.user.name;
  const confirmModal = useConfirmModal();

  const handleRetryApplication = () => {
    confirmModal.openConfirmModal({
      id: modalCode.ERROR_RETRY_SERVICE_APPLY,
    });
  };

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} name={"심사 결과 | 취소"} />
      <Header hasLeftButton={false} />
      <PageContainer align={"center"}>
        <Text typo={"subtitle-1"} bold center>
          {`${name} 사장님\n다시 뵙게 되어 정말 기뻐요!`}
        </Text>
        <Box center width={"100%"}>
          <LottieAnimation src={"happy"} width={240} height={240} />
        </Box>
        <Text color={"content-secondary"} center>
          {
            "변경된 정보가 없다면 이전에 작성하신 정보로\n빠르게 다시 신청이 가능해요"
          }
        </Text>
      </PageContainer>

      <ActionButton
        buttonType={"single"}
        primaryButtonLabel={"다시 신청하기"}
        onClickPrimaryButton={handleRetryApplication}
        primaryButtonProperty={{
          description: "사용자 취소 | 다시 신청하기 버튼",
        }}
      />
    </>
  );
};

export default Restart;
