import React from "react";

import { checkMobile, trackEventMixpanel } from "@earlypay/shared";
import {
  KN_BANK_APP_ANDROID,
  KN_BANK_APP_IOS,
} from "@earlypay/shared/src/common/links";

import { BottomSheet, InfoBox, Text, VStack, useToast } from "@earlybird/ui";

interface KnBankBookGuideProps {
  onClose: () => void;
  visible: boolean;
}

export const KnBankBookGuide = ({ onClose, visible }: KnBankBookGuideProps) => {
  const toast = useToast();

  const handleGoToKnBankApp = () => {
    switch (checkMobile()) {
      case "android":
        trackEventMixpanel("go_to_bnk_bank_app", {
          description: "경남은행 앱으로 이동",
        });
        return window.open(KN_BANK_APP_ANDROID);
      case "iphone":
        trackEventMixpanel("go_to_bnk_bank_app", {
          description: "경남은행 앱으로 이동",
        });
        return window.open(KN_BANK_APP_IOS);
      default:
        return toast.addToast("모바일에서 이용해주세요.");
    }
  };

  return (
    <BottomSheet
      headerType={"none"}
      buttonType={"duo-vertical"}
      primaryButtonLabel={"경남은행 통장 만들기"}
      secondaryButtonLabel={"뒤로"}
      onClickPrimaryButton={handleGoToKnBankApp}
      onClickSecondaryButton={onClose}
      visible={visible}
      onClose={onClose}
    >
      <VStack spacing={8}>
        <VStack spacing={4}>
          <Text typo={"subtitle-2"} bold>
            {"괜찮아요! 앱으로 간편하게 통장을 만들 수 있어요"}
          </Text>
          <Text color={"content-secondary"}>
            경남은행 앱을 설치하고, 신분증만 미리 준비하시면 5분 만에 가입과
            계좌 개설이 가능해요.
          </Text>
        </VStack>

        <InfoBox
          title={"다른 은행은 안되나요?"}
          message={
            "미리 드린 금액을 안전하게 회수하기 위해 얼리페이만 출금 가능하도록 경남은행과 제휴를 맺고 있어요. 더욱 편리한 서비스를 위해 다른 은행과의 제휴도 준비 중이니 조금만 양해 부탁드려요."
          }
        />
      </VStack>
    </BottomSheet>
  );
};

export default KnBankBookGuide;
