import { ApplicationTypes, ImageDocumentsTypes } from "@shared/typings";
import { getCookie } from "@shared/utils/cookies";
import { useQuery } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";

import {
  getApplication,
  getImageDocument,
} from "@/apis/endpoints/applications";
import { applicationState } from "@/recoil/applications/atoms";

import { applicationsQueryKeys } from "./queryKeys";

export const useFetchApplication = () => {
  const accessToken = getCookie("earlypay-token");
  const defaultValue = useRecoilValue(applicationState);

  return useQuery<ApplicationTypes>({
    queryKey: applicationsQueryKeys.fetchApplication().queryKey,
    queryFn: () => getApplication(),
    enabled: !!accessToken,
    placeholderData: defaultValue,
    retry: 1,
  });
};

export const useFetchCardTerminalImages = (applicationId: number) => {
  const accessToken = getCookie("earlypay-token");

  return useQuery({
    queryKey: applicationsQueryKeys.fetchImageDocument(applicationId).queryKey,
    queryFn: () => getImageDocument(applicationId),
    select: (data: { ok: boolean; imageDocuments: ImageDocumentsTypes[] }) => {
      const imageDocuments = data.imageDocuments;
      const result = imageDocuments.filter(
        (item: ImageDocumentsTypes) => item.documentType === "VAN",
      );
      return result;
    },
    enabled: !!applicationId && !!accessToken,
    staleTime: 0,
    retry: 0,
  });
};
