import React, { useState } from "react";

import {
  sentryHook,
  trackEventMixpanel,
  trackPageViewMixpanel,
} from "@earlypay/shared";
import { deliveryAgencySchema } from "@earlypay/shared/common";
import {
  DeliveryAgencyRequests,
  ServiceApplyFormValues,
} from "@earlypay/shared/typings";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { CircleMinusSolidIcon, PlusIcon } from "@earlybird/icons";
import {
  ActionButton,
  BoxButton,
  Input,
  PageContainer,
  Text,
  VStack,
  useToast,
} from "@earlybird/ui";

import AddDeliveryAgencyBottomSheet from "@components/features/ServiceApply/AddDeliveryAgencyBottomSheet";
import { Header } from "@components/layouts";

import useNavigateWithParams from "@hooks/useNavigateWithParams";

import {
  useServiceApplyStageMessage,
  useUpdateApplication,
} from "@apis/hooks/applications/mutations";
import {
  useFetchDeliveryAgents,
  useUpdateDeliveryAgents,
} from "@apis/hooks/delivery";

import { useConfirmModal } from "@ui/components/atoms";

import { applicationState } from "@/recoil/applications/atoms";

import Meta from "../../../components/layouts/Meta";

const DeliveryAgency = () => {
  const application = useRecoilValue(applicationState);
  const { navigateWithParams } = useNavigateWithParams();
  const toast = useToast();
  const confirmModal = useConfirmModal();
  const methods = useForm({
    resolver: yupResolver(deliveryAgencySchema),
    defaultValues: {
      deliveryAgencyBrand: "",
      deliveryAgencyContact: "",
    },
    mode: "onChange",
  });
  const { errors } = methods.formState;
  const deliveryAgencyName = methods.getValues("deliveryAgencyBrand");

  const applicationMutation = useUpdateApplication(application.id);
  const eventMutation = useServiceApplyStageMessage(application.id, {
    stage: "DELIVERY_AGENT",
  });

  const { data: deliveryAgents } = useFetchDeliveryAgents(application.id);
  const mutation = useUpdateDeliveryAgents(application.id);
  const disabled = !!errors["deliveryAgencyContact"] || !deliveryAgencyName;

  const [visibleBottomSheet, setVisibleBottomSheet] = useState<boolean>(false);

  const handlePressNext = () => {
    eventMutation.mutate();
    if (application.serviceType === "DE") {
      trackEventMixpanel("complete_sales_connection");
    }
    trackEventMixpanel("complete_delivery_account");
    navigateWithParams({ pathname: "/service-apply/card-terminal" });
  };

  const handlePressSkip = () => {
    applicationMutation.mutate({ isDeliveryAgentSkipped: true });
    return navigateWithParams({ pathname: "/service-apply/card-terminal" });
  };

  const handleSkippedAgency = () => {
    confirmModal.openConfirmModal({
      id: "TEXT_SKIP_AGENCY",
      onClickPrimaryButton: handlePressSkip,
    });
  };

  const deleteDeliveryAgency = (selectedItem: DeliveryAgencyRequests) => {
    const deletedItem = deliveryAgents.filter(
      item =>
        !(
          item.brand === selectedItem.brand &&
          item.contact === selectedItem.contact
        ),
    );
    mutation.mutate(deletedItem);
  };

  const onSubmit = (values: ServiceApplyFormValues) => {
    mutation.mutate([
      {
        brand: values.deliveryAgencyBrand,
        contact: values.deliveryAgencyContact,
      },
      ...deliveryAgents,
    ]);
    setVisibleBottomSheet(false);
    methods.reset();
  };

  const handleAddDeliveryAgency = () => {
    if (deliveryAgents?.length >= 10) {
      return toast.addToast("최대 10개까지만 등록할 수 있어요.", "warning");
    }

    setVisibleBottomSheet(true);
  };

  return (
    <>
      <Meta title={"이페이론 | 서비스 신청"} name={"배달 대행사 등록"} />
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Header previous={"/service-apply/delivery-account"}></Header>

          <PageContainer spacing={16} overflow={"auto"}>
            <VStack spacing={4}>
              <Text typo={"subtitle-1"} bold>
                {"이용 중인 배달 대행사와\n담당자의 연락처를 알려주세요"}
              </Text>
              <Text as={"span"} color="content-secondary">
                {"배달앱 내의 ‘만나서 결제’ 매출을 미리 받기 위해,\n"}
                <Text as={"span"} color={"content-highlight"}>
                  이용 중인 모든 대행사
                </Text>
                {"의 정보가 필요해요."}
              </Text>
            </VStack>

            <VStack spacing={8}>
              <BoxButton
                icon={PlusIcon}
                hierarchy="secondary"
                onClick={handleAddDeliveryAgency}
              >
                배달 대행사 추가
              </BoxButton>

              {deliveryAgents?.map(
                (item: DeliveryAgencyRequests, index: number) => (
                  <Input
                    key={index}
                    readonly
                    value={item.contact}
                    title={item.brand}
                    trailingIcon={CircleMinusSolidIcon}
                    onClickTrailingIcon={() => deleteDeliveryAgency(item)}
                  />
                ),
              )}
            </VStack>

            <AddDeliveryAgencyBottomSheet
              onClose={() => setVisibleBottomSheet(false)}
              disabled={disabled}
              visible={visibleBottomSheet}
            />
          </PageContainer>

          <ActionButton
            buttonType="duo-vertical"
            primaryButtonLabel="완료"
            secondaryButtonLabel="건너뛰기"
            onClickPrimaryButton={handlePressNext}
            onClickSecondaryButton={handleSkippedAgency}
            disabledPrimaryButton={!deliveryAgents?.length}
            disabledSecondaryButton={!!deliveryAgents?.length}
            loading={mutation.isPending}
            primaryButtonProperty={{
              description: "배달 대행사 등록 완료 버튼",
            }}
            secondaryButtonProperty={{
              description: "배달 대행사 등록 건너뛰기 버튼",
            }}
          />
        </Form>
      </FormProvider>
    </>
  );
};

export default DeliveryAgency;

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
