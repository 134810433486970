import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import { useLocation } from "react-router-dom";

import {
  Image,
  PageContainer,
  SocialLoginButton,
  Text,
  VStack,
} from "@earlybird/ui";

import { useLogin } from "@/hooks/useLogin";

import Meta from "../../components/layouts/Meta";

const Login = () => {
  const { getKakaoLoginUrl } = useLogin();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nextUrl = searchParams.get("nextUrl") ?? "/";

  return (
    <>
      <Meta title={"이페이론 | 로그인"} name={"로그인"} />
      <PageContainer
        padding={"72px 20px 20px 20px"}
        justify={"between"}
        height={"100%"}
      >
        <VStack spacing={30} align={"center"} height={"100%"}>
          <Text typo={"title-3"} bold center>
            {"이제, 기다리지 말고\n365일 얼리페이"}
          </Text>

          <Image
            src={"/service/delivery-card-money.png"}
            alt="이제, 기다리지 말고 365일 얼리페이"
            width={240}
            height={240}
          />
        </VStack>

        <SocialLoginButton
          as={"a"}
          link={getKakaoLoginUrl(nextUrl)}
          type={"KAKAO"}
        />
      </PageContainer>
    </>
  );
};

export default Login;
